const get_address_api_key = process.env.VUE_APP_GET_ADDRESS;
const hostName = process.env.VUE_APP_BASE_URL;
import router from "../../router/router";
import Cookies from "js-cookie";
import axios from "axios";
export default {
  async getAddress(context, payload) {
    // to find all the address corresponding to given postcode
    let url = `https://api.getAddress.io/autocomplete/${payload.value}?api-key=${get_address_api_key}`;
    try {
      let locations = await axios.get(url);
      let address = locations.data;
      return address.suggestions;
    } catch (err) {
      return "";
    }
  },
  async signUp(context, payload) {
    try {
      const reqData = {
        email: payload.email,
        restaurant_name: payload.restaurant_name,
        phone_no: payload.phone_no,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        address: payload.address,
        zip_code: payload.zip_code.split(",")[0],
      };
      const reqUrl = `${hostName}/outlet/signup`;
      let res = await axios.post(reqUrl, reqData);
      if (res.status == 200) {
        Cookies.set("id", res.data.payload.id, { expires: 1 });
        Cookies.set("token", res.data.token, { expires: 1 });
      }
      return res.status;
    } catch (err) {
      return 400;
    }
  },
  async signIn(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
        password: payload.password,
      };
      const reqUrl = `${hostName}/outlet/signin`;
      let res = await axios.post(reqUrl, reqBody);
      if (res.status == 200) {
        Cookies.set("email", res.data.payload.email, { expires: 30 });
        Cookies.set("id", res.data.payload.id, { expires: 30 });
        Cookies.set("restaurant_name", res.data.payload.restaurant_name, {
          expires: 30,
        });
        Cookies.set("userType", res.data.payload.userType, { expires: 30 });
        Cookies.set("token", res.data.token, { expires: 30 });
        Cookies.set("authenticated", true, { expires: 30 });
        router.push({ name: "home" });
        return { status: 200 };
      }
    } catch (err) {
      return {
        status: err.response.status,
        message: err.response.data.message,
        token: err.response.data.token,
      };
    }
  },
  async forgotPassword1(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
      };
      const reqUrl = `${hostName}/outlet/generate-otp-mail`;
      let res = await axios.post(reqUrl, reqBody);
      return res.status;
    } catch (err) {
      return 404;
    }
  }, // this is for email
  async forgotPassword2(context, payload) {
    try {
      const reqBody = {
        email: Cookies.get("password-email"),
        newPassword: payload.password,
        confirmPassword: payload.confirm,
        otp: payload.otp,
      };
      const reqUrl = `${hostName}/outlet/forgot-password`;
      let res = await axios.post(reqUrl, reqBody);
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  }, // this is for otp verification and setting new password
  async putToken(context, payload) {
    // this will put the token
    try {
      const reqBody = {
        fcm_token: payload.value,
      };
      const reqUrl = `${hostName}/outlet/update-fcm-outlet`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      await axios.put(reqUrl, reqBody, {
        headers: headers,
      });
    } catch (err) {
      return;
    }
  },
  async deleteFcm() {
    try {
      let reqUrl = `${hostName}/outlet/delete-fcm-outlet`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.delete(reqUrl, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async getProfile() {
    try {
      let token = Cookies.get("token");
      let reqUrl = `${hostName}/outlet/profile`;
      let reqHeaders = {
        Authorization: "Bearer " + token,
      };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      return {
        status: res.status,
        data: res.data.data,
        cardNumber: res.data.cardLast4,
        cardBrand: res.data.cardBrand,
        expiryMonth: res.data.expiryMonth,
        expiryYear: res.data.expiryYear,
      };
    } catch (err) {
      let stat = err.response.status;
      return { status: stat };
    }
  },
  async fetchNotifications() {
    try {
      const reqUrl = `${hostName}/outlet/notification-history`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.notificationHistory };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  // async stripeOnboard() {
  //   try {
  //     const reqUrl = `${hostName}/outlet/create-account-stripe`;
  //     const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
  //     let res = await axios.post(reqUrl, {}, { headers: reqHeaders });
  //     if (res.status == 200) {
  //       return { status: 200, data: res.data.url };
  //     }
  //   } catch (err) {
  //     return { status: err.response.status };
  //   }
  // },
  async changePassword(context, payload) {
    try {
      const reqUrl = `${hostName}/outlet/reset-password`;
      const reqBody = {
        oldPassword: payload.current,
        newPassword: payload.password,
        confirmPassword: payload.confirm,
      };
      const reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async uploadImage(context, payload) {
    try {
      const formData = new FormData();
      formData.append("profileImage", payload.value);
      let reqUrl = `${hostName}/outlet/profile-image`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      const res = await axios.put(reqUrl, formData, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.data.profile_image };
      }
    } catch (err) {
      console.log(err);
      return { status: err.response.status };
    }
  },
  async addCard() {
    try {
      const reqUrl = `${hostName}/outlet/setup-intent-stripe`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.post(reqUrl, {}, { headers: reqHeaders });

      if (res.status == 200) {
        return { status: 200, data: res.data.client_secret };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
