import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store";
import "./styles/app.css";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyD2bCpouqhLvwsRa49wlE1LKbHJkLifg2A",
  authDomain: "eat-express-32d51.firebaseapp.com",
  projectId: "eat-express-32d51",
  storageBucket: "eat-express-32d51.appspot.com",
  messagingSenderId: "52057041653",
  appId: "1:52057041653:web:014f0234a3598a94ad73bc",
  measurementId: "G-VFP56EJFQ3",
};
const firebaseApp = initializeApp(firebaseConfig);
createApp(App).use(store).use(router).use(firebaseApp).mount("#app");
