<template>
    <div id="container">
        <div id="form" v-if="Message==0">
            <back-button id="back-button" @click="back"></back-button>
            <div id="sign-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="5" viewBox="0 0 74 5" fill="none">
                    <path d="M73.0111 2.64648L70.7017 0.337083L68.3923 2.64648L70.7017 4.95589L73.0111 2.64648ZM70.7017 2.24648H0.527344V3.04648H70.7017V2.24648Z" fill="#25AD34"/>
                  </svg>
                  <h2>Log In</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="5" viewBox="0 0 73 5" fill="none">
                    <path d="M0.333177 2.64648L2.64258 4.95589L4.95198 2.64648L2.64258 0.337083L0.333177 2.64648ZM72.8169 2.24648H2.64258V3.04648H72.8169V2.24648Z" fill="#25AD34"/>
                  </svg>
            </div>
           <div>
            <div id="form-box">
                <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Enter your email" v-model="value1" ref="input-1"  @keydown="moveToNextInput($event, 'input-2')" >
                <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
                <div :class="Error == 2?'input-error':'input-1'" id="password-field">
                    <input :type="Type" class="input-field" placeholder="Enter password" v-model="password" ref="input-2" @keydown="moveToNextInput($event, 'input-3')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="58" viewBox="0 0 60 58" fill="none" v-if="Type=='password'" @click="togglePassword('text')">
                        <path d="M59.8808 28.2957C57.5962 22.1039 53.6125 16.7395 48.4205 12.8636L58.0008 2.92071L55.1807 0L1.99921 55.0793L4.81929 58L15.0196 47.4564C19.5726 50.2047 24.7309 51.6954 30 51.7857C36.5192 51.5318 42.8236 49.3051 48.1341 45.3807C53.4446 41.4563 57.5285 36.0063 59.8808 29.7043C60.0397 29.2492 60.0397 28.7508 59.8808 28.2957ZM30 42.4643C27.2431 42.463 24.5589 41.5484 22.3398 39.8543L25.9999 36.105C27.5175 36.9662 29.2639 37.2945 30.9769 37.0407C32.69 36.787 34.2774 35.9647 35.5011 34.6974C36.7248 33.4301 37.5187 31.786 37.7637 30.0118C38.0087 28.2376 37.6917 26.4289 36.8602 24.8571L40.4803 21.1079C41.8893 23.1122 42.7357 25.481 42.926 27.9524C43.1162 30.4238 42.6428 32.9016 41.5582 35.1118C40.4736 37.322 38.82 39.1785 36.7802 40.4761C34.7404 41.7736 32.3938 42.4617 30 42.4643ZM7.05935 41.035L17.0596 30.6779C17.0076 30.1203 16.9876 29.56 16.9996 29C17.0049 25.4307 18.3763 22.0092 20.8132 19.4854C23.2501 16.9615 26.5537 15.5412 30 15.5357C30.5282 15.5386 31.0557 15.5732 31.58 15.6393L39.1403 7.83C36.1979 6.78332 33.111 6.23767 30 6.21429C23.4808 6.46819 17.1764 8.69494 11.8659 12.6193C6.55537 16.5437 2.47154 21.9937 0.119153 28.2957C-0.0397175 28.7508 -0.0397175 29.2492 0.119153 29.7043C1.65525 33.9355 4.01792 37.7928 7.05935 41.035Z" fill="#32CD32"/>
                      </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="48" viewBox="0 0 61 48" fill="none" v-else @click="togglePassword('password')">
                        <path d="M30.5008 32.7273C34.9928 32.7273 38.6343 28.8199 38.6343 24C38.6343 19.18 34.9928 15.2727 30.5008 15.2727C26.0087 15.2727 22.3672 19.18 22.3672 24C22.3672 28.8199 26.0087 32.7273 30.5008 32.7273Z" fill="#32CD32"/>
                        <path d="M60.8789 23.2582C58.4873 16.6203 54.3354 10.8799 48.9363 6.74637C43.5373 2.61285 37.1278 0.267432 30.5 0C23.8722 0.267432 17.4627 2.61285 12.0637 6.74637C6.66462 10.8799 2.51273 16.6203 0.121138 23.2582C-0.0403795 23.7375 -0.0403795 24.2625 0.121138 24.7418C2.51273 31.3797 6.66462 37.1201 12.0637 41.2536C17.4627 45.3872 23.8722 47.7326 30.5 48C37.1278 47.7326 43.5373 45.3872 48.9363 41.2536C54.3354 37.1201 58.4873 31.3797 60.8789 24.7418C61.0404 24.2625 61.0404 23.7375 60.8789 23.2582ZM30.5 38.1818C27.8859 38.1818 25.3305 37.3501 23.157 35.7918C20.9835 34.2334 19.2894 32.0185 18.289 29.4271C17.2887 26.8358 17.0269 23.9843 17.5369 21.2333C18.0469 18.4823 19.3057 15.9553 21.1541 13.9719C23.0026 11.9886 25.3576 10.6379 27.9215 10.0907C30.4853 9.54347 33.1428 9.82432 35.5579 10.8977C37.973 11.9711 40.0373 13.7888 41.4896 16.121C42.9419 18.4532 43.717 21.1951 43.717 24C43.7117 27.7595 42.3174 31.3633 39.8399 34.0217C37.3624 36.68 34.0037 38.176 30.5 38.1818Z" fill="#32CD32"/>
                      </svg>
                </div>

                <h2 class="error-message" v-if="Error==2">{{ ErrorMessage }}</h2>
            </div>
            <div id="loader" v-if="Loading">
                 <loader-comp></loader-comp>
            </div>
            <div id="alternate">
                Forgot Password?<span id="link" @click="redirect">Click Here</span>
            </div>
            <submit-button Text="Submit" @click="submit"></submit-button>
           </div>
        </div>
        <div class="message-comp" v-if="Message==2">
            <error-comp msg="Failed to login outlet"></error-comp>
           </div>
           <div class="message-comp" v-if="Message==1">
            <server-crash msg="Something went wrong at our end"></server-crash>
           </div>
    </div>
</template>

<script>
import BackButton from '../../components/general/back-button.vue'
import SubmitButton from '../../components/general/submit-button.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import ErrorComp from '../../components/messages/error-comp.vue'
import ServerCrash from '../../components/messages/server-crash.vue'
import Cookies from 'js-cookie'
export default {
    components:{BackButton,SubmitButton,LoaderComp,ErrorComp,ServerCrash},
    data(){
        return{
            value1:'',
            password:"",
            errorField:'',
                errorMessage:"",
                message:0,
                type:'password'
        }
    },
    computed:{
            Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            },
            Loading(){
                return this.$store.getters['user/getLoading']
            },
            Message(){
                return this.message;
            },
            Type(){
                return this.type;
            }
        },
    methods:{
        togglePassword(value){
            this.type=value
        },
        async submit(){
            let check_1 = await this.check1(this.value1)
            let check_2 = await this.check2(this.value1)
            if(this.value1==''){
                this.errorField = 1;
                this.errorMessage = 'This field cannot be empty'
            }
            
            else if(check_1 == false && check_2 == false){
                this.errorField = 1;
                this.errorMessage = "Please enter a valid value"
            }
            else if(this.password==''){
                this.errorField=2;
                this.errorMessage='This field cannot be empty'
            }
           else{
            this.$store.commit('user/setLoading',{value:true})
            let response = await this.$store.dispatch('user/signIn',{
                email:this.value1,
                password:this.password
            })
            console.log(response)
            this.$store.commit('user/setLoading',{value:false})
            if(response.status==200){
                return
            }
            else if(response.status==401){
                this.errorField = 2;
                this.errorMessage = "Wrong password"
            }
            else if(response.status==403 && response.message=='Please, complete your stripe onboarding!'){
                Cookies.set("token", response.token, { expires: 30 });
                this.$router.push({name:'stripeOnboard'})
            }
            else if(response.status==404){
                this.errorField = 1;
                this.errorMessage = "Outlet does not exist"
            }
            else if (response.status==500){
                this.message =1;
                setTimeout(()=>{
                    this.message=0;
                })
            }
            else{
                this.message=2;
                setTimeout(()=>{
                    this.message=0;
                })
            }
           }
        },
        redirect(){
            this.$router.push({name:'forgot'})
        },
        back(){
            this.$router.push({name:'landing'})
        },
        async check1(value){ // phone number
               const phonePattern = /^[0-9]{10}$/; 
               return phonePattern.test(value);
            },
        async check2(value){ // email address
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                return emailPattern.test(value);
            },
             async moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        if(nextInputName=='input-3'){
            await this.submit()
        }
        else{
            this.$refs[nextInputName].focus();
        }
      }
              }
              },
              mounted(){
                if(Cookies.get('token') && Cookies.get('authenticated') == true ){
                    this.$router.push({name:'home'})
                }
              }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
/*
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}*/
#sign-title{
    align-items: center;
    width:100vw;
    display: inline-flex;
    align-items: center; /* Vertical alignment */
justify-content: center; /* Horizontal alignment */
}
#alternate{
    align-items: center;
    width:100vw;
    display: inline-flex;
    align-items: center; /* Vertical alignment */
justify-content: center; /* Horizontal alignment */
}
@media only screen and (max-width:480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        width:100vw;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom:10.5vh;
        height: auto;
        min-height:100vh;
        margin-top:3.5vh;
        padding-bottom:3.5vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #form::-webkit-scrollbar{
        display: none;
    }
    .message-comp{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom:10.5vh;
        height: auto;
        min-height:100vh;
        margin-top:3.5vh;
        padding-bottom:3.5vh;
        overflow-x: hidden;
        overflow-y: auto;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    #back-button{
        margin-left:15px;
        margin-top:15px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 27.99px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:30px;
        margin-right: 30px;
    }
    #sign-title{
        margin-top:20px;
    }
    #form-box{
        margin-top:125px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder,
    .input-field::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-field,
    .input-field::selection,
    .input-field:focus{
        width:70vw;
        height:47px;
background:transparent;
border:none;
outline:none;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:6.75vw;
        margin-top:-19px;
        margin-bottom:10px;
    }
    #loader{
        width:auto;
        margin-top:1.5vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    #password-field{
        display:flex;
        align-items:center
    }
    #password-field svg{
        width:29px;
        height:24px;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        width:100vw;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: auto;
    min-height:100vh;
    margin-top:4vh;
    padding-bottom:2vh;
    overflow-x: hidden;
    overflow-y: auto;
    }
    #back-button{
        margin-left:30px;
        margin-top:20px;
    }
    .message-comp{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: auto;
    min-height:100vh;
    margin-top:4vh;
    padding-bottom:2vh;
    overflow-x: hidden;
    overflow-y: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:30px;
        margin-right: 30px;
    }
    #sign-title{
        margin-top:115px;
    }
    #form-box{
        margin-top:40px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder,
    .input-field::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-field,
    .input-field::selection,
    .input-field:focus{
        width:50vw;
        height:67px;
border:none;
outline:none;
background: transparent;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:-2vw;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:15px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:20.75vw;
        margin-top:-16px;
        margin-bottom:12px;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;

    }
    #password-field{
        display:flex;
        align-items:center;
    }
    #password-field svg{
        width:36px;
        height:32px;
    }
}
@media only screen and (min-width:961px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        width:100vw;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: auto;
        min-height:100vh;
        margin-top:4vh;
        padding-bottom:4vh;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .message-comp{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: auto;
        min-height:100vh;
        margin-top:4vh;
        padding-bottom:4vh;
        overflow-x: hidden;
        overflow-y: auto;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      #back-button{
        margin-left:30px;
        margin-top:20px;
        cursor: pointer;
    }
    #sign-title h2{
        color: #413F3F;
text-align: center;
font-family: Inter;
font-size: 23.99px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:30px;
margin-right:30px
    }
    #sign-title{
        margin-top:5px;
    }
    #form-box{
        margin-top:15vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder,
    .input-field::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:18.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-field,
    .input-field::selection,
    .input-field:focus{
        width:25vw;
        margin-left:-1vw;
        height:47px;
        border-radius: 30px;
background: transparent;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:10px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:4px;
cursor: pointer;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:35.75vw;
        margin-top:-16px;
    }
    #loader{
        width:100vw;
        margin-top:2vh;
        margin-bottom:auto;
        display: flex;
        justify-content: center;
    }
    #password-field{
        display:flex;
        align-items:center;
    }
    #password-field svg{
        width:35px;
        height:30px;
        cursor:pointer
    }
}
</style>